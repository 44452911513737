:root {
    --primary-black: #000000;
    --primary-purple: #887DFF;
    --white: #ffffff;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .gradient-bg {
    background: linear-gradient(45deg, var(--primary-black), var(--primary-purple));
  }
  