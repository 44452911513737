.signup {
  margin-top: 2rem;
  text-align: center;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.emailInput {
  padding: 0.8rem 1.2rem;
  border: 2px solid #eee;
  border-radius: 50px;
  font-size: 1rem;
  width: 300px;
  outline: none;
}

.emailInput:focus {
  border-color: #887DFF;
}

.submitButton {
  background: #887DFF;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.submitButton:hover {
  background: #7A6EE6;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(136, 125, 255, 0.3);
}
