.hero {
    min-height: 89vh;  /* Slightly less than full height */
    margin: 2rem;      /* Add margin around */
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #887DFF 0%, #6B5FD9 100%);
    padding: 2rem;
    position: relative;
    overflow: hidden;
    border-radius: 24px;  /* Rounded corners */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);  /* Subtle shadow */
}

/* Add a white background to the page */
:global(body) {
    background: #f5f5f7;  /* Light gray background */
}

/* Rest of your styles remain the same */
.content {
    text-align: center;
    max-width: 900px;
    z-index: 2;
}


.logoContainer {
    margin: 0 auto 3.5rem;
    width: 250px;
    height: 250px;
    background: white;
    border-radius: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.logoContainer:hover {
    transform: scale(1.02); /* Subtle grow on hover */
}

.logo {
    width: 300px;
    height: auto;
    display: block;
}

.title {
    font-size: 4.5rem;
    font-weight: 800;
    color: white;
    line-height: 1.1;
    margin-bottom: 1.5rem;
    letter-spacing: -0.02em;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.8s ease-out 0.2s both; /* Slide up animation */
}

.subtitle {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.95);
    margin-bottom: 3rem;
    font-weight: 500;
    animation: slideUp 0.8s ease-out 0.4s both; /* Delayed slide up */
}

.readMore {
    background: white;
    color: #887DFF;
    border: none;
    padding: 1.2rem 3.5rem; /* Slightly wider */
    font-size: 1.2rem;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    animation: slideUp 0.8s ease-out 0.6s both; /* More delayed slide up */
    position: relative;
    overflow: hidden;
}

.readMore:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    background: rgba(255, 255, 255, 0.95);
}

.readMore::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: rgba(255, 255, 255, 0.2);
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    transition: transform 0.5s ease-out;
}

.readMore:active::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
}

/* Enhanced background effect */
.hero::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 50%);
    animation: rotate 30s linear infinite;
}

/* New animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .title {
        font-size: 3rem;
    }
    
    .subtitle {
        font-size: 1.4rem;
    }

    .logoContainer {
        width: 220px;
        height: 220px;
        margin-bottom: 2.5rem;
    }

    .logo {
        width: 160px;
    }
}