.features {
    padding: 0;
    max-width: 1200px;
    margin: 0 auto 3rem;  /* Reduced bottom margin */
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;  /* Slightly reduced gap */
    padding: 0 1rem;
}

.featuresTitle {
    font-size: 2rem;
    color: #666;
    margin-bottom: 3rem;
    font-weight: 500;
    /* Remove this title and handle in the component */
}


.card {
    background: white;
    padding: 3rem 2rem;
    border-radius: 24px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 35px rgba(136, 125, 255, 0.15);
}

.card h3 {
    color: #887DFF;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
}

.card p {
    color: #666;
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 280px;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    
    .featuresTitle {
        font-size: 2.5rem;
        margin-bottom: 3rem;
    }
    
    .card {
        padding: 2.5rem 2rem;
    }
    
    .card h3 {
        font-size: 1.6rem;
    }
    
    .card p {
        font-size: 1.1rem;
    }
}