.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 2rem;  /* Reduced padding */
}

.introSection {
    margin: 0 auto 3rem;  /* Reduced margin */
    max-width: 900px;
}

.titleWrapper {
    margin-bottom: 1.5rem;  /* Reduced margin */
    text-align: center;
}

.introTitle {
    font-size: 4.5rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 0.25rem;  /* Reduced margin */
    line-height: 1.1;
}

.introSubtitle {
    font-size: 2.8rem;
    color: #887DFF;
    line-height: 1.2;
    margin-bottom: 1.5rem;  /* Added small margin */
}

.introText {
    font-size: 1.4rem;
    line-height: 1.6;
    color: #666;
    margin: 0 auto 2rem;  /* Reduced margin */
    max-width: 800px;
    text-align: center;
}

/* Features section spacing */
.features {
    margin-top: 2rem;  /* Reduced margin */
}

@media (max-width: 768px) {
    .content {
        padding: 2rem 1.5rem;
    }

    .introTitle {
        font-size: 3rem;
    }

    .introSubtitle {
        font-size: 2.2rem;
    }

    .introText {
        font-size: 1.2rem;
    }
}